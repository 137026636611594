<template>
  <div>
    <landing-page-header />
    <div v-if="loading" class="tw-flex tw-justify-center tw-items-center tw-h-screen">
      <Loader/>
    </div>
    <div v-else class="wrapper d-flex md:pt-16 mt-16 md:tw-px-12 tw-px-8 tw-w-10/12 tw-overflow-x-scroll md:tw-overflow-x-hidden">
      <div class="flex-grow-1 mr-0 mr-sm-10">
        <div class="d-flex justify-space-between mb-8">
          <h1 class="page--title">Cart</h1>
          <span v-if="carts.length" class="cart--length">{{ carts.length ? carts.length : 'No' }} item(s)</span>
        </div>
        <div v-if="carts.length">
          <div v-for="(cart) in carts" class="my-6 border-bottom pb-8" :key="cart.merch_id">
            <cart-item :cart="cart" @select="cart.selected = !cart.selected" @delete="[deleteItem(cart.merch_id)]" />
          </div>
        </div>
        <div v-if="!loading && !carts.length" class="md:tw-mt-20 md:tw-mx-80">
          <empty-state-for-cart>
            <template>
              <div>
                <v-btn text rounded class="button tw-mb-8 md:tw-mb-0" @click="$router.push('/build')">BUILD MERCH</v-btn>
              </div>
            </template>
          </empty-state-for-cart>
        </div>
      </div>
      <div class="checkout mt-16" v-if="carts.length">
        <div class="your__order">
          <order-summary :products="selectedCarts" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from "@/components/addToCart/CartItem";
import LandingPageHeader from "@/components/LandingPageHeader";
import OrderSummary from "@/components/addToCart/OrderSummary";
import CartRetrieverMixin from "@/mixins/cartRetrieverMixin";
import {addMerchToCart, getItemInCart, removeItemInCart} from "@/services/auth-services";
import Loader from "@/reuseable/Loader";
import isUserLoggedIn from "@/mixins/isUserLoggedIn";
import EmptyStateForCart from "@/components/addToCart/EmptyStateForCart.vue";


export default {
  name: "Cart",
  components: {EmptyStateForCart, Loader, OrderSummary, LandingPageHeader, CartItem},
  mixins: [CartRetrieverMixin,isUserLoggedIn],
  data(){
    return{
    }
  },
  watch: {
    carts: {
      handler: 'updateStorageCarts',
      deep: true
    }
  },
  async created() {

    if(JSON.parse(localStorage.getItem('cart')) && this.getLogInStatus){
      await this.addItemToCart()
    }
    if (this.$route.query.id) {
      const cartItem = this.carts.filter(cart => cart.merch_id !== this.$route.query.id)
      if (cartItem) {
        cartItem.selected = true
      }
    }
  },
  methods: {
   async deleteItem (id) {
      // this.carts = this.carts.filter(cart => cart.merch_id !== id)
      let itemId = this.carts.find(cart => cart.merch_id === id)
     await removeItemInCart({
        id: itemId.merch_id
      }).then(res=>{
        console.log(res)
       let index = this.carts.findIndex(item => item.merch_id === id)
       this.carts.splice(index, 1);
       this.$displaySnackbar({
         message: 'Item deleted successfully',
         success: true
       })
      }).catch(err=>{
            this.loading= false
            console.log(err)
          })
    },
    updateStorageCarts (carts) {
      !this.getLogInStatus && localStorage.setItem('cart', JSON.stringify(carts))
    },
    async addItemToCart(){
      let cart = JSON.parse(localStorage.getItem('cart'))
      addMerchToCart({merches:cart}).then(res=>{
        console.log(res)
          localStorage.removeItem('cart')
      })
        .catch(err=>{
          this.loading= false
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .page--title {
    color: #00050D;
    font-size: 32px;
    line-height: 41.66px;
    font-weight: 700;
  }

  .cart--length {
    color: #514A41;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
  }

  .checkout {
    min-width: 400px;
  }

  .border-bottom:not(:last-child) {
    border-bottom: 1px solid #EAEAEA;
  }
}
</style>
