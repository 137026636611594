<template>
  <v-btn text  rounded to="/build" class="merch"  >{{buttonText}}</v-btn>
</template>

<script>
export default {
  name: "BuildMerchButton",
  props:{
    buttonText:{
      type: String,
      default : "BUILD MERCH"
    }
  }
}
</script>

<style scoped>
.merch{
  padding: 8px 16px;
  min-width: 128px;
  width: auto;
  height: 34px;
  background: #FF7A01;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
  text-transform: capitalize;
}
</style>