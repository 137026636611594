<template>
  <div class="order-summary pa-5 ">
    <h5 class="box--title">YOUR ORDER</h5>
    <div class="products mt-5">
      <div v-for="(product, i) in products" :key="i" class="product tw-w-full justify-space-between flex-wrap d-flex py-3 bottom--border">
        <div>
          <p class="mb-0">{{ product.name || product.merch_data.merch_name }} x {{ product.count }}</p>
        </div>
        <div>
          <span class="product--price">&#8358;{{ formatPrice($getItemPrice(product)) }}</span>
        </div>
      </div>
      <div class="d-flex justify-space-between total--price my-5">
        <div>Total</div>
        <div class="price">&#8358;{{ total }}</div>
      </div>
      <span class="my-5 instruction">Shipping not included</span>
      <div class="my-6">
        <v-btn color="primary" @click="checkout" :disabled="products.length === 0" rounded block depressed large class="white--text">Checkout</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import priceMixin from "@/mixins/priceMixin";
import isUserLoggedIn from "@/mixins/isUserLoggedIn";
import CartRetrieverMixin from "@/mixins/cartRetrieverMixin";
import {getItemPrice} from "@/services/helpers";


export default {
  name: "OrderSummary",
  mixins: [priceMixin, isUserLoggedIn,CartRetrieverMixin],
  props: {
    products: Array
  },
  computed: {
    total () {
      return this.formatPrice(this.products.reduce((acc, curr) => {
        console.log(curr, acc)
        return getItemPrice(curr) + acc
      }, 0))
    }
  },
  methods: {
    checkout () {
      if(!this.getLogInStatus){
        this.$router.push({ name: 'Login' ,query:{redirect:'Cart'}})

      }
      else{
        localStorage.setItem('selectedCartItem',JSON.stringify(this.products))
        this.$router.push({ name: 'Shipping' })

      }
    },
    $getItemPrice: (item) => getItemPrice(item)
  }
}
</script>

<style lang="scss" scoped>
.order-summary {
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  width: 100%;
  max-width: 330px;
  margin: auto;

  @media (min-width: 991px) {
    max-width: 400px;
  }
}

.bottom--border {
  border-bottom: 1px solid #EAEAEA;
}

.product--price {
  font-weight: 700;
  font-size: 16px;
}

.total--price {
  font-size: 18px;

  & .price {
    font-weight: 700;
  }
}

.instruction {
  color: #A1A1A1;
  font-size: 14px;
  font-weight: 400;
}

.box--title {
  color: #514A41;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.62px;
  letter-spacing: .1em;
}
</style>
