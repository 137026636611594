<template>
  <div class="d-flex">

    <div class="flex-grow-1">
      <div class="d-flex justify-space-between">
        <div>
          <h5 class="cart__title">{{ cart.merch_name }}</h5>
          <p class="cart__name">{{ cart.name || cart.product_name }}</p>
          <p v-for="(v, k, i) in sizes || cart.sizes" :key="i">
            {{sizesAliases[k]}}: {{v}}
          </p>
          <h5 class="amount mt-4">
            &#8358;{{ $getItemPrice }}
          </h5>
        </div>
        <div>
          <v-btn @click="$emit('select')" class="select--btn" :class="{'not-selected': !cart.selected}" outlined :color=" cart.selected ? 'primary' : '#514A41'" rounded>{{ cart.selected ? 'SELECTED': 'SELECT' }}</v-btn>
        </div>
      </div>
      <div class="d-flex justify-space-between align-center">
        <div class="shirt__image mr-4 mt-2 tw-flex">
          <!--      <front-merch image-mobile-width="40px" image-height="40px" image-width="40px" class="mr-1"  :json-style="f" :width="'40px'" :height="'40px'"  :load-from-j-s-o-n="true" />-->
          <!--      <back-merch image-width="50px" image-height="50px" :json-style="cart.merch_data" :width="'20px'" :height="'20px'"  :load-from-j-s-o-n="true"/>-->
          <!--      <v-img v-if="cart.merch_data" width="60" height="80" :src="cart.merch_data.front"/>-->

          <!--      <v-img v-else width="60" height="80" :src="cart.front_url"/>-->
          <v-img
              width="60"
              height="80"
              v-if="$hasDesignOn()"
              :src="shirt"
          />
          <v-img
              width="60"
              height="80"
              :src="shirt2"
              v-if="$hasDesignOn('back')"
              class="tw-ml-4"
          />

        </div>
        <div>
          <v-btn icon @click="$emit('delete')">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.4829 6.39852L15.7601 16.5173C15.6978 17.3894 14.9721 18.0652 14.0977 18.0652H7.20144C6.32704 18.0652 5.60131 17.3894 5.53901 16.5173L4.81624 6.39852M8.98291 9.73185V14.7319M12.3162 9.73185V14.7319M13.1496 6.39852V3.89852C13.1496 3.43828 12.7765 3.06519 12.3162 3.06519H8.98291C8.52267 3.06519 8.14958 3.43828 8.14958 3.89852V6.39852M3.98291 6.39852H17.3162" stroke="#514A41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import priceMixin from "@/mixins/priceMixin";
import { fabric } from "fabric";
import { mapGetters, mapState } from "vuex";
import FrontMerch from "@/components/build/FrontMerch";
import BackMerch from "@/components/build/BackMerch";
import {getFrontImage, getBackImage} from "@/store/buildMerch/getters";
import {getItemPrice, hasDesignOn} from "@/services/helpers";

export default {
  name: "CartItem",
  components: { BackMerch, FrontMerch },
  mixins: [priceMixin],
  data: () => ({
    sizesAliases: {
      S: 'Small' || 'small',
      M: 'Medium' || 'medium',
      L: 'Large' || 'large',
      XL: 'Extra Large' || 'extra_large',
      XXL: 'Extra Extra Large' || 'extra_extra_large'
    },
    canvas:null
  }),
  computed: {
    sizes() {
      const { sizes } = this.cart
      const chosenSizes = {}
      for (const size in sizes) {
        if (sizes[size]) {
          chosenSizes[size] = sizes[size]
        }
      }
      return chosenSizes
    },
    ...mapState('buildMerch',
      [
        'canvasJson',

      ]),
    ...mapGetters('buildMerch', ['selectedShirt']),
    f () {
      return localStorage.getItem('frontBuildJson')
    },
    shirt () {
      return getFrontImage(this.cart.color_code)
    },
    shirt2 () {
      return getBackImage(this.cart.color_code)
    },
    $getItemPrice () {
      return this.formatPrice(getItemPrice(this.cart))
    }
  },
  props: {
    cart: Object
  },
  methods: {
    $hasDesignOn (where = 'front') {
      return hasDesignOn(this.cart, where)
    }
  }
}
</script>

<style scoped lang="scss">
.cart__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #00050D;
}

.cart__name {
  color: #514A41;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.select--btn:not(.not-selected) {
  background: #FFF8EF !important;
  border-color: #FFF8EF !important;
}

.select--btn.not-selected {
  border: 1px solid #F9F4EE;
}

p {
  margin-bottom: 0;
}

.amount {
  color: #00050D;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

::v-deep {
  .shirt__container {
    min-width: 0px;
  }
}
</style>
