import { getItemInCart } from "@/services/auth-services";

export default {
    data: () => ({
        carts: [],
        cartsFromAPI :[],
        loading:false
    }),
    async created () {
        let cart = JSON.parse(localStorage.getItem('cart'))
      let isLoggedIn = JSON.parse(localStorage.getItem('userToken'))
      console.log(cart,isLoggedIn)

      if(isLoggedIn && !cart){
        await this.getItemInCart()

      }
      else {

        if (cart) {
          this.carts = JSON.parse(localStorage.getItem('cart'))
        }
      }

    },
    methods:{
        async getItemInCart(){
          if(!this.carts.length) {

            this.loading = true
            getItemInCart().then(
              res => {
                console.log(res)
                this.carts = res.data.cart_items.map(({ product_name, sizes, front_url, merch_id, merch_name, color_code, back_url }) => ({
                  product_name,
                  price: 2500,
                  name: product_name,
                  sizes,
                  selected: false,
                  front_url,
                  merch_id,
                  merch_name,
                  id: merch_id,
                  image: front_url,
                color_code,
                back_url,
                    count: Object.values(sizes).reduce((acc, curr) => curr + acc, 0)
                }))
                this.loading = false

              }
            )
              .catch(err => {
                console.log(err)
                this.loading = false

              })
          }
        },

    },
    computed: {
        selectedCarts () {
            return this.carts.filter(cart => cart.selected)
        },
    }
}
