<template>
  <div class="md:tw-w-11/12 tw-m-auto tw-py-6  tw-px-4 tw-flex tw-justify-between tw-items-center">
    <div>
      <Logo class="tw-hidden md:tw-block"/>
      <LogoWithoutWord class="md:tw-hidden"/>
    </div>
    <div class="tw-flex tw-justify-between">
      <div class="tw-flex tw-items-center">
        <div @click="$router.push({name:'Cart'})" class="tw-cursor-pointer tw-flex mr-2">
          <v-icon color="black">mdi-cart-outline

          </v-icon>
          <v-avatar v-if="carts.length" class="mt-n2" size="20" color="red">
            <span  class=" white--text tw-text-sm">{{carts.length}}</span>

          </v-avatar>
        </div>
      </div>

      <div class="md:tw-flex tw-hidden">
        <build-merch-button/>
      </div>

      <div v-if="!getLogInStatus" class="">
        <v-btn class="login" rounded text to="/login">
          <v-icon class="mr-1" color="black">mdi-account-outline</v-icon>
          LOGIN
        </v-btn>
      </div>
      <div v-if="getLogInStatus" class=" d-flex tw-items-center tw-justify-center">

        <v-menu offset-y class="logged-in" >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="d-flex tw-items-center tw-justify-center ml-4"
          >
          <v-img class="mr-1" width="25" height="25" style="border-radius: 50%" :src="profileImage" >
          </v-img>
        <span>{{userProfile.first_name}}</span>
        </div>
      </template>
      <v-list>
        <v-list-item to="/profile">
          <v-list-item-title class="tw-flex tw-items-center mr-6">
            <v-icon class="mr-1" color="#AAAAAA">mdi-account-outline</v-icon>
            <span> Profile</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="userLogout()">
          <v-list-item-title class="tw-flex tw-items-center mr-6">
           <img class="mr-2" src="@/assets/logout.svg" alt="">
           <span> Logout</span>
            </v-list-item-title>
        </v-list-item>
      </v-list>
        </v-menu>


      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/reuseable/Logo";
import BuildMerchButton from "@/reuseable/BuildMerchButton";
import LogoWithoutWord from "@/reuseable/LogoWithoutWord";
import CartRetrieverMixin from "@/mixins/cartRetrieverMixin";
import isUserLoggedIn from "@/mixins/isUserLoggedIn";
import {mapGetters} from "vuex";
import {logout } from "@/services/auth-services";


export default {
  name: "LandingPageHeader",
  components: { LogoWithoutWord, BuildMerchButton, Logo },
  mixins: [CartRetrieverMixin,isUserLoggedIn],

  data() {
    return {
      fixed: false
    };
  },
  computed:{
    userProfile(){
      return JSON.parse(localStorage.getItem('userData'))
    },
    ...mapGetters('profile',['profileInfo']),
    profileImage(){
      // if (this.profileInfo) return this.profileInfo.image;
      return this.$getUserData.fromLocalStorage()?.image || 'https://res.cloudinary.com/spacehubpictures/image/upload/v1657317163/kneek/kqjxlana8u4kaikstsrw.png'
    }

  },
  methods:{
    userLogout(){
      logout()
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #292929;
  text-transform: none;
}

.header-frame {
  display: flex;
  justify-content: space-between;
}

.login {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #292929;

}

.v-menu__content{
    background: #FFFFFF;
    border: 1px solid #F9F8F8;
    box-shadow: 0px 12px 24px rgb(173 173 173 / 8%), 0px 2px 4px rgb(173 173 173 / 4%);
    border-radius: 8px;
    margin-top: 8px;
}

</style>
